<template>
  <ion-content
    :scroll-events="true"
    @ionScroll="handleScroll($event)"
    class="scroller default-background"
    v-if="!loading || isLoadMore"
  >
    <NewProductItem
      :key="item.id"
      :item="item"
      :user="user"
      :selectedCompany="selectedCompany"
      v-for="item in list.items || []"
      @item-clicked="toProductDetail"
    />

    <!-- INFINITY scroll -->
    <ion-infinite-scroll
      @ionInfinite="loadMore"
      threshold="100px"
      id="infinite-scroll"
      :disabled="isDisabled"
    >
      <div class="d-flex justify-center align-center pb-2" v-if="isShowLoading && !isConnectionIssue">
        <ion-spinner name="bubbles"></ion-spinner>
        <ion-text class="ml-1">{{ $t('please_wait') }}...</ion-text>
      </div>
    </ion-infinite-scroll>
    <div v-if="!loading && list.items.length === 0 && !isFirstRenderPage" class="company-not-found mx-4">
      <ion-img src="/assets/images/not-found.svg" class="not-found-img" />
      <div
        class="not-found-text mt-3 mb-2"
        v-html="
          isConnectionIssue
            ? $t('unable_load')
            : $t('sorry_not_found', {
                search_keyword: search?.searchQueries === '' ? refKeySearch : search?.searchQueries
              })
        "
      ></div>
      <div class="try-different">
        {{ isConnectionIssue ? $t('please_make_sure_connected') : $t('try_different_keyword') }}
      </div>
    </div>
  </ion-content>

  <div v-if="loading && !isLoadMore">
    <skeleton-product-item v-for="(item, index) in 4" :key="index" :detail="false" class="mx-3 my-3" />
  </div>
</template>
<script>
import halal from '@/assets/images/b2b/home/halal.svg';
import NewProductItem from '@/components/product-item/NewCardProductItem.vue';
import { getItemByKey, useUpdateFilter } from '@/components/sort/composable';
import { TYPE_SEARCH, defaultSize } from '@/modules/b2b/constants';
import { ACTIONS } from '@/modules/b2b/store/product/actions';
import { GETTER, MODULES_FILTER, OFFSET_LIST_DEFAULT } from '@/modules/shared/constants/';
import { useAlert } from '@/modules/shared/utils';
import { useConfig, useGetStorageData } from '@/usecases/';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { computed, defineAsyncComponent, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/product');
const { mapGetters: mapGettersCustomer } = createNamespacedHelpers('b2b/customer');

import {
  discountFn,
  marketPriceFn,
  priceDiscountFn,
  priceFn,
  weightFn
} from '@/modules/b2b/services/libs/home';
export default defineComponent({
  components: {
    SkeletonProductItem: defineAsyncComponent(() => import('./SkeletonProductItem.vue')),
    NewProductItem
  },
  props: {
    changeCategory: {
      type: String,
      default: ''
    },
    selectedNewCustomer: {
      type: Object,
      default: null
    }
  },
  emits: ['fetchAllProduct', 'load-data', 'closeLoading', 'showTabOption', 'reloadHomePage'],

  setup(props, { emit }) {
    const { router, t, store } = useConfig();
    const { selectedCompany: selectedCompanyOnStorage } = useGetStorageData();
    const { createAlert } = useAlert();
    const user = ref(null);
    const selectedCompany = ref(null);
    const category = ref(null);
    const isShowList = ref(false);
    const isShowLoading = ref(false);
    const tenantId = ref(0);
    const productId = ref(0);
    const route = useRoute();
    const isFirstRenderPage = ref(true);
    const skuIds = ref([]);
    const noLoadItems = ref(false);
    const listCompanies = computed(() => store.getters[`b2b/customer/list`]);
    // loading
    const { onSetDefaultSort, updateFilter } = useUpdateFilter();
    const selectedTabCategory = ref(props.changeCategory);
    const validateCustomerData = async () => {
      const { id, account_number } = await selectedCompanyOnStorage.value;
      if (!id || !account_number) {
        if (listCompanies.value?.data?.length <= 1) {
          createAlert(
            t('missing_customer_data_label'),
            t('missing_customer_data_desc'),
            () => emit('reloadHomePage'),
            t('refresh')
          );
          return;
        } else {
          createAlert(
            t('missing_customers_data_label'),
            t('missing_customers_data_desc'),
            () => router.replace('/b2b/select-customer'),
            t('selectCompany.select_company')
          );
          return;
        }
      }
    };
    return {
      validateCustomerData,
      onSetDefaultSort,
      updateFilter,
      user,
      selectedCompany,
      category,
      isShowList,
      isShowLoading,
      route,
      isFirstRenderPage,
      priceFn,
      weightFn,
      priceDiscountFn,
      marketPriceFn,
      discountFn,
      tenantId,
      productId,
      halal,
      selectedTabCategory,
      skuIds,
      noLoadItems,
      Keyboard,
      refKeySearch: ref(``),
      isDisabled: ref(false),
      isConnectionIssue: ref(false)
    };
  },
  computed: {
    ...mapGetters(['list', 'loading', 'isLoadMore', 'search', 'resetCategory', 'getProductStatus']),
    ...mapGettersCustomer(['selectedCustomer']),

    currencySymbol() {
      return this.user ? this.user.currency_symbol : '';
    }
  },

  watch: {
    async 'list.totalCount'() {
      this.isFirstRenderPage = false;
      if (this.list.totalCount === 0 || this.list.totalCount === this.list.items.length) {
        this.isDisabled = true; // remove spriner
      } else {
        this.isDisabled = false;
      }
    },
    selectedCustomer: {
      async handler(newCompany, oldCompany) {
        if (oldCompany && newCompany && newCompany.id !== oldCompany.id) {
          this.selectedTabCategory = 'history';
          this.selectedCompany = newCompany;
        }
      },
      deep: true
    },
    async selectedNewCustomer() {
      await this.onSearch('history');
    },
    getProductStatus() {
      let isError = this.getProductStatus;
      this.isConnectionIssue =
        isError === this.$t('load_failed') || isError === this.$t('failed_to_fetch') ? true : false;
    },
    async search() {
      this.isShowList = false;
      this.category = this.selectedTabCategory;
      this.search.searchQueries !== '' ? (this.refKeySearch = this.search.searchQueries) : '';

      await this[ACTIONS.GET_PRODUCTS]({
        isLoadMore: this.isLoadMore
      });

      if (this.list.totalCount === 0 || this.list.totalCount === this.list.items.length) {
        this.isDisabled = true; // remove spriner
      }
    },
    list() {
      this.isDisabled = false;
      this.list.category == this.category ? (this.isShowList = true) : '';
      this.isShowLoading =
        this.list.totalCount !== 0 && this.list.totalCount > this.list.items.length ? true : false;
      if (this.list.totalCount === 0 || this.list.totalCount === this.list.items.length) {
        this.isDisabled = true; // remove spriner
      }
    },
    resetCategory() {
      this.category = null;
    },
    changeCategory() {
      this.selectedTabCategory = this.changeCategory;
    },
    loading() {
      if (!this.loading) {
        this.$emit('closeLoading');
      }
    }
  },

  inject: ['$storage'],

  async mounted() {
    this.user = await this.$storage.getUser();
    this.selectedCompany = await this.$storage.getSelectedCompany();
    if (this.changeCategory !== '') {
      this.selectedTabCategory = this.changeCategory;
      await this.onSearch(this.selectedTabCategory);
    } else {
      this.selectedTabCategory = 'history';
      await this.onSearch(this.selectedTabCategory);
    }
  },

  methods: {
    ...mapActions([ACTIONS.UPDATE_SEARCH, ACTIONS.GET_PRODUCTS, ACTIONS.CHOOSE_PRODUCT]),
    async onSearch(cate) {
      await this.validateCustomerData();
      if (this.selectedNewCustomer) {
        this.selectedCompany = this.selectedNewCustomer;
      }
      this.isShowList = false;
      if (this.search.isExplore) {
        this.search.isExplore = false;
        // update category
        this.category = this.selectedTabCategory;

        // update filter to most popular
        this.updateFilter(this.search.sortBy);

        // perform search
        await this[ACTIONS.GET_PRODUCTS]({
          isLoadMore: this.isLoadMore,
          search: this.search
        });
      } else {
        this.onSetDefaultSort(cate);
        const sortItemKey = this.$store.getters[`${MODULES_FILTER}/${GETTER.GET_FILTER}`];
        const itemSort = getItemByKey({ categoryName: cate, key: sortItemKey });
        // update search value
        const search = {
          searchQueries: this.search.searchQueries !== '' ? this.search.searchQueries : '',
          tenantId: 'joined',
          buyerId: this.selectedCompany.id + '',
          halal: this.selectedCompany.halal_products,
          account_number: this.selectedCompany.account_number,
          limit: defaultSize,
          offset: OFFSET_LIST_DEFAULT,
          sortBy: cate === 'history' ? 'purchased' : itemSort.sortBy,
          sortType: cate === 'history' ? 'desc' : itemSort.sortType,
          mainCategoryId: cate
        };
        await this[ACTIONS.UPDATE_SEARCH]({ search, type: TYPE_SEARCH.CATEGORY });
      }
      this.isShowList = true;
    },

    handleScroll(event) {
      if (event && Capacitor.isNativePlatform()) {
        this.Keyboard.hide();
      }
    },

    async loadMore(e) {
      try {
        if (this.noLoadItems) return;

        this.noLoadItems = true;
        // check continue status
        if (this.list.totalCount !== 0 && this.list.totalCount === this.list.items.length) {
          this.isDisabled = true;
          return;
        }
        await this.getProduct();
        e.target.complete();
      } catch (error) {
        this.noLoadItems = true;
      } finally {
        this.noLoadItems = false;
      }
    },
    getProduct() {
      this.$emit('load-data');
    },
    fetchAllProduct() {
      this.$emit('fetchAllProduct');
    },
    toProductDetail(item) {
      this.$router.push({
        path: `/b2b/main/product-detail/${item.id}`
      });
    }
  }
});
</script>

<style lang="scss" scoped>
ion-item::part(native) {
  padding: 0;
}
.scroller {
  height: 100%;
  --padding-top: 16px;
  --padding-bottom: 16px;
}

.default-background {
  --background: #f5f5f5;
}

.name {
  font-weight: 500;
  font-size: 16px;
  white-space: pre-wrap;
  line-height: 20px;
}

.tenant {
  font-size: 1rem;
}

.price {
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.03333em;
  color: var(--ion-color-grey);
}

.weight {
  font-size: 1rem;
  color: var(--ion-color-primary);
}

.marketPrice {
  font-size: 0.72rem;
  color: var(--ion-color-grey);
  text-decoration: line-through;
}

.discount {
  background: #fff1f1;
  color: #e56b68;
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 0.72rem;
}

.company-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  margin-top: 12vh;
}
.not-found-img {
  height: 120px;
  width: 120px;
}
.not-found-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.7px;
}
.try-different {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.7px;
}
</style>
